<template>
  <common-trs-page-container>
    <common-trs-page-header title="Access Control" />
    <template v-slot:page-content>
      <common-app-page-header
        :title="'New User'"
      />
      <div class="trs-form-container-card">
        <new-user-form
          v-model="userData"
          :roles="roles"
          @create="createCaptableUser"
        />
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableAdminService from '@/services/captable-admin-service'
  import NewUserForm from './components/NewUserForm'

  export default {
    name: 'AddCaptableUser',

    components: {
      NewUserForm,
    },

    data: () => ({
      userData: {},
      roles: [],
      loading: true,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    mounted () {
      this.getCaptableRoles()
    },

    methods: {
      async getCaptableRoles () {
        try {
          this.loading = true
          const resp = await captableAdminService.getCaptableRoleList()
          this.roles = resp.results
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load roles list',
          })
        }
        this.loading = false
      },
      async createCaptableUser () {
        try {
          this.loading = true
          await captableAdminService.createCaptableUser(this.captableId, this.userData)
          this.$store.commit('app/showMessage', { text: 'The password confirmation email was sent.' })
          this.$router.push({ name: 'SettingsAccess' })
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to create user. Please try later',
          })
        }
        this.loading = false
      },
    },
  }
</script>
