<template>
  <common-trs-card class="pa-4">
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
    >
      <v-card-text class="pa-0">
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            :name="'Username'"
          >
            <common-input-text-field
              v-model="internalValue.username"
              label="Username"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required|noNumbers"
            :name="'First Name'"
          >
            <common-input-text-field
              v-model="internalValue.first_name"
              label="First Name"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="noNumbers"
            :name="'Middle Name'"
          >
            <common-input-text-field
              v-model="internalValue.middle_name"
              label="Middle Name (optional)"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required|noNumbers"
            :name="'Last Name'"
          >
            <common-input-text-field
              v-model="internalValue.last_name"
              label="Last Name"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Role'"
          >
            <common-input-select
              v-model="internalValue.roles"
              multiple
              label="Role"
              :items="roles"
              :error-messages="errors"
              color="primary"
              item-value="role_name"
              item-text="role_display_name"
              validate-on-blur
            />
          </validation-provider>
        </v-form>
      </v-card-text>
      <v-divider class="mb-3" />
      <v-row
        justify="end"
        no-gutters
      >
        <v-spacer />
        <common-trs-btn
          type="tertiary"
          class="mr-2 primary--text"
          @click="cancel"
        >
          Cancel
        </common-trs-btn>
        <common-trs-btn
          type="primary"
          :disabled="!valid"
          :loading="loading"
          class="mr-0 white--text"
          @click="create"
        >
          Create
        </common-trs-btn>
      </v-row>
    </validation-observer>
  </common-trs-card>
</template>

<script>
  export default {
    name: 'NewUserForm',

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: false,
      },
      roles: {
        type: Array,
        required: true,
      },
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
      cancel () {
        this.$router.back()
      },
      create () {
        this.$emit('create')
      },
    },
  }
</script>
