var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-trs-card',{staticClass:"pa-4"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',[_c('validation-provider',{attrs:{"rules":"required|email","name":'Username'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Username","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.username),callback:function ($$v) {_vm.$set(_vm.internalValue, "username", $$v)},expression:"internalValue.username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|noNumbers","name":'First Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"First Name","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.first_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "first_name", $$v)},expression:"internalValue.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"noNumbers","name":'Middle Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Middle Name (optional)","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.middle_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "middle_name", $$v)},expression:"internalValue.middle_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|noNumbers","name":'Last Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Last Name","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.last_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "last_name", $$v)},expression:"internalValue.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":'Role'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"multiple":"","label":"Role","items":_vm.roles,"error-messages":errors,"color":"primary","item-value":"role_name","item-text":"role_display_name","validate-on-blur":""},model:{value:(_vm.internalValue.roles),callback:function ($$v) {_vm.$set(_vm.internalValue, "roles", $$v)},expression:"internalValue.roles"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-spacer'),_c('common-trs-btn',{staticClass:"mr-2 primary--text",attrs:{"type":"tertiary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"mr-0 white--text",attrs:{"type":"primary","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.create}},[_vm._v(" Create ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }